import React, { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';

import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import TitleLabel from '../../components/title-label/title-label.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import CameraSettingFields from '../../components/camera-setting-fields/camera-setting-fields.component';
import CameraLocationDialog from '../../components/camera-location-dialog/camera-location-dialog.component';
import DialogComponent from '../../components/dialog/dialog.component';
import ModifyDialogComponent from '../../components/dialog/modify-dialog.component';
import CameraSelectEditable from '../../components/camera-select/camera-select-editable.component';

import { selectCameraItems } from '../../redux/camera/camera.selectors';
import { fetchCameraStart } from '../../redux/camera/camera.actions';

import {
    createCameraStart,
    modifyCameraStart,
    removeCameraStart,
} from '../../redux/camera/camera.actions';

import { oprStatusMap } from '../../redux/camera/camera.utils';

import './camera-setting.css';
import { BorderBottom, HowToVoteRounded } from '@mui/icons-material';

const CameraSettingPage = ({ setValue }) => {
    const dispatch = useDispatch();
    const allCameraItems = useSelector(selectCameraItems);
    const [currCameraItem, setCurrCameraItem] = useState({
        imei: '',
        name: '',
        river: '',
        place: '',
        lat: '',
        lon: '',
        camera_status: '',
        level_thresh1: '',
        level_thresh2: '',
        youtube_url: '',
        em_url: '',
    });
    // Check if user clicked "yes" to changing IMEI
    const [isModifyConfirmed, setIsModifyConfirmed] = useState(false);
    const [isModifyConfirmed2, setIsModifyConfirmed2] = useState(false);
    const [isCreateConfirmed, setIsCreateConfirmed] = useState(false);
    /**
     * To modify text in confirm dialogs
     */
    // Check if Confirm dialog is for DELETE or IMEI
    const [isDeleteDialog, setIsDeleteDialog] = useState(true);
    // Check if MODIFY confirm dialog is for USER INPUT / DROPDOWNLIST
    const [isUserInput, setIsUserInput] = useState(true);
    /**
     * To open dialogs
     */
    // map dialog
    const [openLocationDialog, setOpenLocationDialog] = useState(false);
    // IMEI and DELETE camera confirm dialog
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    // Confirm PUT camera dialog for USER INPUT / DROPDOWNLIST
    const [openConfirmDialog2, setOpenConfirmDialog2] = useState(false);

    /**
     * Use effect hooks
     */
    // to set navbar to camera-setting
    useEffect(() => {
        let timer;
        dispatch(fetchCameraStart('CAMERA-SETTING'));
        timer = setInterval(() => {
            dispatch(fetchCameraStart('CAMERA-SETTING'));
        }, 300000);
        return () => {
            clearInterval(timer);
        };
    }, [dispatch]);
    // set currently selected camera to 1st by default, resets to 1st if allCameraItems changes
    useEffect(() => {
        const selectedFeature = allCameraItems.find(
            (item) => item.name === currCameraItem.name,
        );
        if (selectedFeature) {
            setCurrCameraItem((currentState) => ({
                ...currentState,
                id: selectedFeature.id,
                lat: selectedFeature.lat,
                lon: selectedFeature.lon,
            }));
        } else {
            setCurrCameraItem((currentState) => ({
                ...currentState,
                id: null,
                lat: '',
                lon: '',
            }));
        }
    }, [allCameraItems, currCameraItem.name]);
    useEffect(() => {
        if (isModifyConfirmed && isModifyConfirmed2) {
            dispatch(modifyCameraStart(currCameraItem));
            setIsModifyConfirmed(false);
            setIsModifyConfirmed2(false);
        }
    }, [
        dispatch,
        isModifyConfirmed,
        isModifyConfirmed2,
        currCameraItem,
    ]);
    useEffect(() => {
        if (isCreateConfirmed) {
            currCameraItem.group &&= currCameraItem.group.toString();
            dispatch(createCameraStart(currCameraItem));
            setIsCreateConfirmed(false);
        }
    }, [dispatch, isCreateConfirmed, currCameraItem]);
    useEffect(() => {
        setValue(3);
    }, [setValue]);

    /**
     * handler functions
     */
    // handle change of textfields
    const handleChange = useCallback((event) => {
        let decimalRegex = /^\d*\.?\d*$/;
        let { value, name } = event.target;
        if (name === 'camera_status')
            value = Object.values(oprStatusMap).indexOf(value).toString();
        // remember to implement checking lv2 > lv1
        if (name === 'level_thresh1' || name === 'level_thresh2') {
            if (!decimalRegex.test(value)) {
                return;
            }
        }
        setCurrCameraItem((currentState) => ({
            ...currentState,
            [name]: value,
        }));
    }, []);

    // handle change of camera selection dropdown
    const handleChangeDropdown = useCallback(
        (event, newValue) => {
            if (newValue) {
                setIsUserInput(false);
                // change currently selected camera
                const selectedFeature = allCameraItems.find(
                    (item) => item.name === newValue.name,
                );
                setCurrCameraItem(selectedFeature);
            }
        },
        [allCameraItems],
    );

    // handle change of user input (camera ID)
    const handleChangeInputValue = useCallback((event, newValue) => {
        if (event.type === 'change') {
            setIsUserInput(true);
        }
        setCurrCameraItem((currentState) => ({
            ...currentState,
            name: newValue,
        }));
    }, []);

    // open confirmation dialog for delete
    const handleClickDeleteButton = () => {
        if (currCameraItem.name === '')
            return toast.error('カメラ名が入力されていません。', {
                duration: 5000,
            });
        if (currCameraItem && allCameraItems.length) {
            const selectedFeature = allCameraItems.find(
                (item) => item.name === currCameraItem.name,
            );
            if (!selectedFeature) {
                return toast.error('カメラ名が存在していません。', {
                    duration: 5000,
                });
            } else {
                setCurrCameraItem((currentState) => ({
                    ...currentState,
                    id: selectedFeature.id,
                }));
            }
        }
        setIsDeleteDialog(true);
        setOpenConfirmDialog(true);
    };

    // To handle IMEI change and camera DELETE confirmation
    const handleCloseIMEIAndDelete = (e) => {
        if (e.currentTarget.value === 'MODIFY') {
            setIsModifyConfirmed(true);
            setIsModifyConfirmed2(true);
        } else if (e.currentTarget.value === 'DELETE') {
            startDeleteFunction();
        }
        setOpenConfirmDialog(false);
    };

    // To modify dialog confirmation
    const handleCloseModifyConfirm = (e) => {
        if (e.currentTarget.value === 'yes') {
            setIsModifyConfirmed2(true);
        }
        setOpenConfirmDialog2(false);
    };

    // trigger removeCameraStart() action
    const startDeleteFunction = useCallback(() => {
        dispatch(removeCameraStart(currCameraItem));
    }, [currCameraItem, dispatch]);

    // trigger modifyCameraStart() action
    const handleRegisterButton = useCallback(() => {
        const errorArr = [];

        const specialCharRegex = /[&<>"']/;
        const engNumSymRegex = /^[a-zA-Z0-9-]+$/;

        if (currCameraItem.name === '')
            errorArr.push('カメラ名が入力されていません。');
        else if (specialCharRegex.test(currCameraItem.name))
            errorArr.push('カメラ名に& < > \" \' は使用できません。');

        if (currCameraItem.imei === '')
            errorArr.push('カメラIDが入力されていません。');
        else if (currCameraItem.imei.length > 20)
            errorArr.push('カメラIDは20文字以内で指定してください。');
        else if (!engNumSymRegex.test(currCameraItem.imei))
            errorArr.push(
                'カメラIDは、半角英数字と「-(ハイフン)」のみ' + 
                'を使用してください。'
            );


        if (currCameraItem.camera_status === '')
            currCameraItem.camera_status = '0';

        if (currCameraItem.lon === '' || currCameraItem.lat === '')
            errorArr.push('カメラ設置場所が設定されていません。');

        if (
            currCameraItem.level_thresh1 === '' ||
            !currCameraItem.level_thresh1
        )
            errorArr.push('警戒水位閾値Lv1が入力されていません。');

        if (
            currCameraItem.level_thresh2 === '' ||
            !currCameraItem.level_thresh2
        )
            errorArr.push('警戒水位閾値Lv2が入力されていません。');

        if (
            !(
                currCameraItem.level_thresh1 === '' &&
                currCameraItem.level_thresh2 === ''
            )
        ) {
            if (
                parseFloat(currCameraItem.level_thresh1) >=
                parseFloat(currCameraItem.level_thresh2)
            )
                errorArr.push(
                    '警戒水位閾値は、Lv1 ＜ Lv2 となるように入力してください。',
                );
        }

        if (currCameraItem.river === '')
            errorArr.push('河川名が入力されていません。');
        else if (specialCharRegex.test(currCameraItem.river))
            errorArr.push('河川名に& < > \" \' は使用できません。');

        if (currCameraItem.place === '')
            errorArr.push('現在の住所が入力されていません。');
        else if (specialCharRegex.test(currCameraItem.place))
            errorArr.push('現在の住所に& < > \" \' は使用できません。');


        const urlRegExp = new RegExp(
            '(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+' +
            '[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+' +
            '[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))' +
            '[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})'
        );
        if (currCameraItem.youtube_url === '') {
            // errorArr.push('YouTubeライブのURLが入力されていません。');
        } else {
            if (!urlRegExp.test(currCameraItem.youtube_url)) {
                errorArr.push(
                    'YoutubeライブのURLは正しい形式で入力してください。'
                );
            }
        }
        if (currCameraItem.em_url === '') {
            errorArr.push('管理コンソールのURLが入力されていません。');
        } else {
            if (!urlRegExp.test(currCameraItem.em_url)) {
                errorArr.push(
                    '管理コンソールのURLは正しい形式で入力してください。'
                );
            }
        }


        if (errorArr.length > 0) {

            let outputStr = errorArr[0] + '\n';
            for (let i = 1; i < errorArr.length; i++) {
                outputStr += '\n' + errorArr[i] + '\n';
            }

            toast.error(outputStr, {
                duration: 5000,
            });
        } else {

            // reset IMEI and PUT confirmation to false
            setIsModifyConfirmed(false);
            setIsModifyConfirmed2(false);
            const selectedFeature = allCameraItems.find(
                (item) => item.name === currCameraItem.name,
            );
            if (selectedFeature) {
                if (selectedFeature.imei !== currCameraItem.imei) {
                    setIsDeleteDialog(false);
                    setOpenConfirmDialog(true);
                    return;
                } else {
                    setOpenConfirmDialog2(true);
                    setIsModifyConfirmed(true);
                }
            } else {
                setIsCreateConfirmed(true);
            }
        }
    }, [currCameraItem, allCameraItems]);

    return (<>
        <div className="cameraSettingsPage">
        <div style={{ display: 'flex', alignItems: 'center', gridArea: 'camera-selection' }}>
                <label htmlFor="camera-select" style={{ marginRight: '10px' }}>カメラ名 </label>
                <CameraSelectEditable
                    id="camera-select"
                    {...{
                        allCameraItems,
                        handleChangeDropdown,
                        handleChangeInputValue,
                    }}
                    currCameraItem={currCameraItem}
                />
            </div>
            <CustomButton
                  type="button"
                  onClick={() => setOpenLocationDialog(true)}
                  style={{
                      gridArea: 'camera-location',
                      justifySelf: 'end'
                  }}
            >
                  カメラ設置場所
            </CustomButton>
            
            <CameraSettingFields
                {...{ currCameraItem, handleChange }}
                style={{gridArea: 'camera-info'}}
            />
            
            <div>
            </div>
            <div className="inputMenu" style={{ gridArea: 'youtube-info', display: 'block', backgroundColor: 'white' }}>
                <div>
                    <TitleLabel
                        title="YouTubeライブ"
                        style={{ gridArea: 'youtube-info-title', borderBottom: 'none', boxShadow: 'none' }}
                    />
                </div>
                <div style={{ display: 'flex', paddingTop: '20px', width: '100%' }}>
                    <div className="inputMenuLabel">URL</div>
                    <div style={{ width: '90%' }}>
                        <Tooltip
                            title="YouTubeライブのURLを指定してください。"
                            arrow
                        >
                            <TextField
                                sx={{
                                    width: '100%',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'black',
                                        },
                                    }
                                }}
                                size='small'
                                label="YouTubeライブ入力"
                                name="youtube_url"
                                id="youtube-required"
                                type="url"
                                onChange={handleChange}
                                value={currCameraItem.youtube_url}
                                variant="outlined"
                                InputLabelProps={{
                                    required: false,
                                    sx: {
                                        fontFamily: 'inherit',
                                      '& .MuiInputLabel-asterisk': {
                                        color: 'red',
                                      }
                                    }
                                  }}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>

            <div className="inputMenu" style={{ gridArea: 'edgematrix-info', display: 'block', backgroundColor: 'white' }}>
                <div>
                    <TitleLabel
                        title="管理コンソール"
                        style={{ gridArea: 'edgematrix-info-title', borderBottom: 'none', boxShadow: 'none' }}
                    />
                </div>
                <div style={{ display: 'flex', paddingTop: '20px', width: '100%' }}>
                    <div className="inputMenuLabel">URL</div>
                    <div style={{ width: '90%' }}>
                        <Tooltip
                            title="管理コンソールのURLを指定してください。"
                            arrow
                        >
                            <TextField
                                sx={{
                                    width: '100%',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'black',
                                        },
                                    }
                                }}
                                size='small'
                                label="管理コンソール入力"
                                name="em_url"
                                id="edgematrix-required"
                                type="url"
                                onChange={handleChange}
                                value={currCameraItem.em_url}
                                variant="outlined"
                                InputLabelProps={{
                                    required: true,
                                    sx: {
                                        fontFamily: 'inherit',
                                      '& .MuiInputLabel-asterisk': {
                                        color: 'red',
                                      }
                                    }
                                  }}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className="controlButtons" style={{gridArea: 'camera-info-controls'}}>
                <CustomButton
                    type="button"
                    value="register"
                    onClick={handleRegisterButton}
                >
                    カメラ情報登録
                </CustomButton>
                <CustomButton
                    type="button"
                    value="delete"
                    onClick={handleClickDeleteButton}
                    style={{ backgroundColor: '#D22B2B' }}
                >
                    カメラ情報削除
                </CustomButton>
            </div>
        </div>
        <DialogComponent
            open={openConfirmDialog}
            handleClose={handleCloseIMEIAndDelete}
            deleteDialog={isDeleteDialog}
        />
        <ModifyDialogComponent
            open={openConfirmDialog2}
            handleClose={handleCloseModifyConfirm}
            isUserInput={isUserInput}
        />
        <CameraLocationDialog
            {...{
              openLocationDialog,
              setOpenLocationDialog,
              setCurrCameraItem,
              currCameraItem,
            }}
            cameraId={currCameraItem.id}
        />
    </>);
};

export default CameraSettingPage;
