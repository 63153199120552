import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TitleLabel from '../../components/title-label/title-label.component';
import CameraMapHOC from '../../components/camera-map/camera-map.container';
import CameraTableHOC from '../../components/camera-table/camera-table.container';

import { fetchCameraStart } from '../../redux/camera/camera.actions';

import './camera-list.css';

const CameraListPage = ({ setValue }) => {
    const dispatch = useDispatch();
    const [currPage, setCurrPage] = useState(0);
    const [selectedCamera, setSelectedCamera] = useState(null);
    const [firstCameraOnPage, setFirstCameraOnPage] = useState(null);


    useEffect(() => {
        setValue(1);
    }, [setValue]);

    useEffect(() => {
        let timer;
        dispatch(fetchCameraStart('CAMERA-LIST'));
        timer = setInterval(() => {
            dispatch(fetchCameraStart('CAMERA-LIST'));
        }, 300000);
        return () => {
            clearInterval(timer);
        };
    }, [dispatch]);

    const handleCameraClick = (camera) => { 
        setSelectedCamera(camera);
    }

    const handlePageChange = (firstCamera) => {
        setFirstCameraOnPage(firstCamera);
    }

    const handleSearchClear = () => {
        setSelectedCamera(null);
    }

    return (
        <div className="cameraListPage">
            <TitleLabel 
                title={'河川監視カメラ設置箇所'}
                style={{gridArea: 'map-title'}}
            />
            <CameraMapHOC {...{ currPage, selectedCamera, firstCameraOnPage }} />
            <CameraTableHOC {...{ currPage, setCurrPage, onCameraClick: handleCameraClick, onPageChange: handlePageChange, onSearchClear: handleSearchClear}} />
        </div>
    );
};

export default CameraListPage;
