import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TitleLabel from '../../components/title-label/title-label.component';
import CameraAllCard from '../../components/camera-all-card/camera-all-card.component';
import CameraAllPagination from '../../components/camera-all-pagination/camera-all-pagination.component';

import { selectCameraItems } from '../../redux/camera/camera.selectors';
import { fetchCameraStart } from '../../redux/camera/camera.actions';

import './camera-all.css';

const CameraAllPage = ({ setValue }) => {
    const dispatch = useDispatch();
    const cameraList = useSelector(selectCameraItems);
    const [page, setPage] = useState(1);
    useEffect(() => {
        let timer;
        dispatch(fetchCameraStart('CAMERA-ALL', page));
        timer = setInterval(() => {
            dispatch(fetchCameraStart('CAMERA-ALL', page));
        }, 300000);
        return () => {
            clearInterval(timer);
        };
    }, [dispatch, page]);
    useEffect(() => {
        setValue(0);
    }, [setValue]);
    return (
        <div className="cameraAllPage">
            <TitleLabel 
                title={'全カメラ 最新画像'}
                style={{gridArea: 'page-title', width: '200px'}}
            />
            <CameraAllPagination {...{ page, setPage }} />
            <div style={{gridArea: 'empty'}}/>
            {cameraList.map((item) => (
                <CameraAllCard cameraId={item.id} key={item.id} />
            ))}
        </div>
    );
};

export default CameraAllPage;
