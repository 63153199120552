import styled from 'styled-components';

export const CameraGraphContainer = styled.div`
  grid-area: camera-graph;
  background-color: white;
  padding: 0 10px 0px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`;
