import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import Amplify, { Storage } from 'aws-amplify';
import awsconfig from './aws-exports';

import NavBar from './components/navbar/navbar.component';
import LoginPage from './pages/login-page/login-page.component';
import CameraAllPage from './pages/camera-all/camera-all.component';
import CameraListPage from './pages/camera-list/camera-list.component';
import CameraDetailPage from './pages/camera-detail/camera-detail.component';
import CameraSettingPage from './pages/camera-setting/camera-setting.component';
import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import { RequireAuth } from './pages/login-page/login-page.wrapper';

import {
  checkUserSession,
} from './redux/user/user.actions';

import { selectIdList } from './redux/camera/camera.selectors';

import {
  selectCurrentUser,
} from './redux/user/user.selectors';
import styled from 'styled-components';

Amplify.configure(awsconfig);
Storage.configure({
    customPrefix: {
        public: '' // change to current user group
    }
});

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
`;

const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 40px;
  height: 100%;
`;

function App() {
  const cameraIdList = useSelector(selectIdList);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch]);
  return (
    <AppContainer>
      <Header />
      <NavBar
        {...{
          currentUser,
          cameraIdList,
          value,
          handleChange,
        }}
      />
      <MainContent>
     <ContentWrapper>
     <Routes>
        <Route
          path="/"
          element={<Navigate replace to="/camera-all" />}
        />
        <Route
          path="*"
          element={<Navigate replace to="/camera-all" />}
        />
        <Route
          path="/camera-all"
          element={
            <RequireAuth>
              <CameraAllPage {...{ setValue }} />
            </RequireAuth>
          }
        />
        <Route
          path="/camera-list"
          element={
            <RequireAuth>
              <CameraListPage {...{ setValue }} />
            </RequireAuth>
          }
        />
        <Route
          path="/camera-list/:cameraId"
          element={
            <RequireAuth>
              <CameraDetailPage {...{ setValue }} />
            </RequireAuth>
          }
        /> 
        <Route
          path="/camera-setting"
          element={
            <RequireAuth>
              <CameraSettingPage {...{ setValue }} />
            </RequireAuth>
          }
        />
        <Route
          path="/login"
          element={
            <RequireAuth loginRoute={true}>
              <LoginPage />
            </RequireAuth>
          }
        />
      </Routes>
     </ContentWrapper>
      </MainContent>
      <Footer />
      <Toaster
        toastOptions={{
          //   style: {
          //     width: 200,
          //   },
          success: {
            duration: 1500,
          },
        }}
        containerStyle={{ top: 150 }}
      />
    </AppContainer>
  );
}

export default App;
