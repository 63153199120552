import styled from 'styled-components';

export const CameraInfoContainer = styled.div`
  grid-area: camera-info;
  display: block;
  background-color: white;
  border-radius: 5px;
`
export const CameraSettingFieldsContainer = styled.div`
  grid-area: camera-info;
  width: 100%;
  height: 350px;
  display: grid;
  grid-gap: 7px;
  padding: 15px;
  padding-left: 25px;
  padding-top: 0px;
  grid-template-columns: 100px 1fr;
  grid-template-rows: repeat(5, 1fr);

  border-radius: 5px;
`;

export const LabelContainer = styled.span`
  display: flex;
  justify-content: start;
  grid-column: 1;
  align-items: center;
  margin-bottom: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  grid-column: 2;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
`;

export const AllThresholdContainer = styled.div`
  width: 80%;
  height: 50px;
  display: grid;
  column-gap: 17px;
  grid-template-columns: 10% 40% 10% 40%;
  grid-template-rows: repeat(1, 1fr);
  grid-column: 2;
  grid-row: 2;
`;

export const ThresholdLabelContainer1 = styled.span`
  display: flex;
  justify-content: center;
  grid-column: 1;
  grid-row: 1;
  align-items: center;
`;

export const ThresholdContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  grid-column: 2;
  grid-row: 1;
  /* align-items: center; */
`;

export const ThresholdLabelContainer2 = styled.span`
  display: flex;
  justify-content: center;
  grid-column: 3;
  grid-row: 1;
  align-items: center;
`;

export const ThresholdContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  grid-column: 4;
  grid-row: 1;
  /* align-items: center; */
`;
