import { useState, useCallback } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';

import CameraMapHoc from '../camera-map/camera-map.container';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../custom-button/custom-button.component';

import PinImage from '../../assets/normal_pin.png';
import GreenCctvImage from '../../assets/analysis_green.png';

import './camera-location-dialog.css';

const CameraLocationDialog = ({
    openLocationDialog,
    setOpenLocationDialog,
    setCurrCameraItem,
    currCameraItem,
    cameraId,
    hideMoveIcon
}) => {
    const [coordinates, setCoordinates] = useState([]);
    // handle closing of current User Management dialog
    const handleCloseLocationDialog = () => {
        setOpenLocationDialog(false);
    };
    // handle change of coordinates in camera map
    const handleGeoChange = useCallback((mapCoor) => {
        setCoordinates([mapCoor[0].toString(), mapCoor[1].toString()]);
    }, []);

    const handleSettingButton = () => {
        if (coordinates.length) {
            setCurrCameraItem((currentState) => ({
                ...currentState,
                lon: coordinates[0].toString(),
                lat: coordinates[1].toString(),
            }));
        }
        setOpenLocationDialog(false);
    };

    return (
        <Dialog
            open={openLocationDialog}
            onClose={handleCloseLocationDialog}
            fullWidth={true}
            maxWidth="xl"
            PaperProps={{style: {height: '80%'}}}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#3bb6a9',
                    color: 'white',
                    fontWeight: '530',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                }}
            >
                <span style={{flexGrow: 1}}>カメラ設置場所</span>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseLocationDialog}
                    sx={{
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                className="cameraLocationDialog"
            >
                <div className="cameraLocationHelp">
                    <span 
                        style={{
                            backgroundColor: 'rgba(128, 128, 128, 0.2)',
                            padding: '3px'
                        }}
                    >
                        設置箇所
                    </span>
                    カメラを移動する場所を地図上でクリックしてください。
                </div>
                <CameraMapHoc
                    url={cameraId}
                    handleGeoChange={hideMoveIcon ? null : handleGeoChange}
                    {...{ currCameraItem }}
                />
                <div className="cameraLocationIcons">
                    <span style={{gridColumn: 'span 2', justifySelf: 'center'}}>
                        凡例
                    </span>
                    <img 
                        src={GreenCctvImage}
                        alt="平常"
                        style={{justifySelf: 'end', margin: '0'}}
                    />
                    <span style={{alignSelf: 'center', margin: '0'}}>現在地</span>
                    <img 
                        src={PinImage} 
                        alt="ユーザが設定された設置場所" 
                        style={{justifySelf: 'end'}}
                    />
                    <span style={{alignSelf: 'center'}}>移動先</span>
                </div>
                <CustomButton
                    type="button"
                    value="confirm"
                    onClick={handleSettingButton}
                    style={{gridArea: 'confirm', alignSelf: 'end'}}
                >
                    設定
                </CustomButton>
            </DialogContent>
        </Dialog>
    );
};

export default CameraLocationDialog;
