import React from 'react';

import { Autocomplete, TextField } from '@mui/material';

const CameraSelectEditable = ({
  allCameraItems,
  currCameraItem,
  handleChangeDropdown,
  handleChangeInputValue,
}) => {
  return (
    <Autocomplete
      id="camera-id-editable"
      onChange={handleChangeDropdown}
      value={currCameraItem}
      inputValue={currCameraItem.name}
      onInputChange={handleChangeInputValue}
      freeSolo
      selectOnFocus
      options={allCameraItems}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      sx={{
        width: '50%',
        backgroundColor: 'white',
        //borderLeft: '3px solid #3fb8ac',
        //borderBottom: '1px solid #3fb8ac',
        boxShadow: 'none',
        size: 'small',
      }}
      
      renderInput={(params) => (
        <TextField {...params}
        sx={{
          '& .MuiInputBase-root': {
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            padding: '0px',
            borderColor: 'black',
            '&.Mui-focused': {
                borderColor: 'transparent',
              },
          },
          '& .MuiInputLabel-root': {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            top: '50%',
            paddingLeft: '10px',
            transform: 'translateY(-50%)',
            fontFamily: 'inherit',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            top: 20,
            transform: 'translateY(-100%)',
            fontSize: '13px',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '0px',
          },
          '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'black',
                      },
                    }
        }} 
        variant='outlined'
        label="カメラ名入力・選択"
        InputLabelProps={{
          required: true,
          sx: {
            fontFamily: 'inherit',
            '& .MuiInputLabel-asterisk': {
              color: 'red',
            }
          }
        }}
        />
      )}
    />
  );
};

export default CameraSelectEditable;
