import styled from 'styled-components';

export const UserTableContainer = styled.div`
  width: 950px;
  height: 310px;
  margin: 0 33.5px 35px 35px;
  .MuiTypography-root {
    padding-top: 10px;
  }
  .MuiInputAdornment-root {
    margin-top: 2px;
  }
  
  .MuiTablePagination-select {
    margin-bottom: 12px;
  }

  .MuiSelect-icon {
    margin: 0 0 12px 0;
    position: absolute;
    height: 30%;
  }

  .MuiBox-root {
    margin-bottom: 12px;
  }

  .MuiPaper-root {
    box-shadow: none !important;
  }
  
`;
