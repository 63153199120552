import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import toast from 'react-hot-toast';

import {
    selectCameraItems,
    selectIdList,
    selectIsCameraLoaded,
    selectCameraItem
} from '../../redux/camera/camera.selectors';
import { selectIsPictureLoaded } from '../../redux/picture/picture.selectors';
import { weatherareasLoading } from '../../redux/weatherareas/weatherareasSlice';

import { fetchCameraStart } from '../../redux/camera/camera.actions';
import {
    fetchPictureStart,
    clearPictureUnmount,
} from '../../redux/picture/picture.actions';
import {
    fetchWaterlevelStart,
    fetchLatestWaterlevelStart,
} from '../../redux/waterlevel/waterlevel.actions';
import { fetchWeatherStart } from '../../redux/weather/weather.actions';
import { fetchGaugeareaStart } from '../../redux/gaugearea/gaugearea.actions';

import TitleLabel from '../../components/title-label/title-label.component';
import CameraSelect from '../../components/camera-select/camera-select.component';
import TextLabel from '../../components/text-label/text-label.component';
import DateTime from '../../components/datetime-picker/datetime-picker.component';
import Spinner from '../../components/spinner/spinner.component';
import CameraCarouselHOC from '../../components/camera-carousel/camera-carousel.container';
import CameraGraphHOC from '../../components/camera-graph/camera-graph.container';
import CustomButton from '../../components/custom-button/custom-button.component';
import CrossSectionalView from '../../components/cross-sectional-view/cross-sectional-view.component';
import { CSVExportButton } from './csv-export-button.component';

import './camera-detail.css';
import { Widgets } from '@mui/icons-material';

const CameraDetailPage = ({ setValue }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cameraId } = useParams();
    const allCameraItems = useSelector(selectCameraItems);
    const currentCamera = useSelector(selectCameraItem(cameraId));
    const cameraIdList = useSelector(selectIdList);
    const isCameraLoaded = useSelector(selectIsCameraLoaded);
    const [currCameraId, setCurrCameraId] = useState(cameraId);
    const [currImageIndex, setCurrImageIndex] = useState(0);
    const [dateRange, setDateRange] = useState([null, null]);

    useEffect(() => {
        dispatch(fetchCameraStart('CAMERA-DETAIL'));
        return () => {
            dispatch(clearPictureUnmount());
        };
    }, [dispatch]);

    useEffect(() => {
        if (isCameraLoaded && cameraIdList.includes(currCameraId) === false) {
            toast.error('カメラ情報がありません。');
        }
    }, [isCameraLoaded, cameraIdList, currCameraId]);

    useEffect(() => {
        setValue(2);
    }, [setValue]);

    // when user has not choose date range, fetch latest pictures every 5 minutes
    useEffect(() => {
        let automodeTimer;
        let latestWaterlevelTimer;

        if (isCameraLoaded && cameraIdList.includes(currCameraId) === true) {
            dispatch(fetchGaugeareaStart(cameraId));
            const weatherAreaId = allCameraItems.find(camera => camera.id === Number(cameraId)).weatherarea;
            dispatch(
                fetchPictureStart([cameraId], 'CAMERA-DETAIL', [
                    moment().subtract(18, 'hours').format('YYYY-MM-DDTHH:mm'),
                    moment().format('YYYY-MM-DDTHH:mm'),
                ]),
            );
            dispatch(
                fetchWaterlevelStart(cameraId, [
                    moment().subtract(18, 'hours').format('YYYY-MM-DDTHH:mm'),
                    moment().add(6, 'hours').format('YYYY-MM-DDTHH:mm'),
                ]),
            );
            dispatch(
                fetchLatestWaterlevelStart(
                    [cameraId],
                    [
                        moment().subtract(18, 'hours').format('YYYY-MM-DDTHH:mm'),
                        moment().add(6, 'hours').format('YYYY-MM-DDTHH:mm'),
                    ],
                ),
            );
            dispatch(
                fetchWeatherStart(weatherAreaId, [
                    moment().subtract(18, 'hours').format('YYYY-MM-DDTHH:mm'),
                    moment().add(6, 'hours').format('YYYY-MM-DDTHH:mm'),
                ]),
            );
            dispatch(weatherareasLoading());
            if (!dateRange[0] && !dateRange[1]) {
                automodeTimer = setInterval(() => {
                    dispatch(
                        fetchPictureStart([cameraId], 'CAMERA-DETAIL', [
                            moment().subtract(18, 'hours').format('YYYY-MM-DDTHH:mm'),
                            moment().format('YYYY-MM-DDTHH:mm'),
                        ]),
                    );
                    dispatch(
                        fetchWaterlevelStart(cameraId, [
                            moment().subtract(18, 'hours').format('YYYY-MM-DDTHH:mm'),
                            moment().add(6, 'hours').format('YYYY-MM-DDTHH:mm'),
                        ]),
                    );
                    dispatch(
                        fetchLatestWaterlevelStart(
                            [cameraId],
                            [
                                moment()
                                .subtract(18, 'hours')
                                .format('YYYY-MM-DDTHH:mm'),
                                moment().add(6, 'hours').format('YYYY-MM-DDTHH:mm'),
                            ],
                        ),
                    );
                    dispatch(
                        fetchWeatherStart(weatherAreaId, [
                            moment().subtract(18, 'hours').format('YYYY-MM-DDTHH:mm'),
                            moment().add(6, 'hours').format('YYYY-MM-DDTHH:mm'),
                        ]),
                    );
                }, 300000);
            } else {
                dispatch(
                    fetchPictureStart([cameraId], 'CAMERA-DETAIL', dateRange),
                );
                dispatch(fetchWaterlevelStart(cameraId, dateRange));
                dispatch(fetchWeatherStart(weatherAreaId, dateRange));
                automodeTimer = setInterval(() => {
                    dispatch(
                        fetchLatestWaterlevelStart(
                            [cameraId],
                            [
                                moment()
                                .subtract(18, 'hours')
                                .format('YYYY-MM-DDTHH:mm'),
                                moment().add(6, 'hours').format('YYYY-MM-DDTHH:mm'),
                            ],
                        ),
                    );
                }, 300000);
            }
        }

        return () => {
            clearInterval(automodeTimer);
            clearInterval(latestWaterlevelTimer);
        };
    }, [isCameraLoaded, dispatch, cameraId, dateRange, allCameraItems, currCameraId, cameraIdList]);

    // handle change of camera selection dropdown
    const handleChangeDropdown = useCallback(
        (event) => {
            // change currently selected camera
            setCurrCameraId(event.target.value);
            navigate(`/camera-list/${event.target.value}`, {
                replace: false,
            });
        },
        [navigate],
    );

    if (isCameraLoaded === false)
        return <Spinner/>;

    if (cameraIdList.includes(currCameraId) === false)
        return <Navigate replace to="/camera-list" />;

    return (
        <div className="cameraDetailPage">
            <CameraSelect {...{
                allCameraItems,
                currCameraId,
                handleChangeDropdown,
            }} />
            <div className="timeSelection">
                <TextLabel value={'header'}>日時指定：</TextLabel>
                <DateTime
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    selectIsDataLoaded={selectIsPictureLoaded}
                />
            </div>
            <TitleLabel
                titleFor={'camera-detail'}
                cameraId={currCameraId}
                title={`カメラ`}
                style={{gridArea: 'camera-title', width: '300px'}}
            />
            <CameraCarouselHOC 
                cameraId={cameraId}
                currImageIndex={currImageIndex}
                setCurrImageIndex={setCurrImageIndex}
            />
            <CameraGraphHOC
                cameraId={cameraId}
                timespan={dateRange}
                setCurrImageIndex={setCurrImageIndex}
            />
            <CrossSectionalView cameraId={cameraId} />
            <div className="cameraDetailButtons">
                {currentCamera.youtube_url && (
                    <CustomButton
                        style={{ backgroundColor: 'white', color: '#3fb8ac', border: '1px solid #3fb8ac' }}
                        type="button"
                        onClick={() => window.open(currentCamera.youtube_url, 'youtube')}
                    >
                        YouTubeライブ
                    </CustomButton>
                )}
                <CustomButton
                    style={{ backgroundColor: 'white', color: '#3fb8ac', border: '1px solid #3fb8ac' }}
                    type="button"
                    onClick={
                        () => window.open(currentCamera.em_url, 'edgematrix')
                    }
                    disabled={typeof currentCamera.em_url !== 'string'}
                >
                    管理コンソール
                </CustomButton>
                <CSVExportButton camera={currentCamera} />
            </div>
        </div>
    );
};

export default CameraDetailPage;
