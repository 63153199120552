import styled, { css } from 'styled-components';

const buttonStyles = css`
  background-color: #3fb8ac;
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 8px 7px 10px 1px rgba(0, 0, 0, 0.4);
  /* border: 1px solid black; */
  &:hover:enabled {
    background-color: #2a8179;
    color: white;
    /* border: 1px solid black; */
  }
`;

const invertedButtonStyles = css`
  background-color: white;
  color: black;
  /* border: 1px solid black; */

  &:hover {
    background-color: black;
    color: white;
    border: none;
  }
`;

const getButtonStyles = (props) => {
  return props.inverted ? invertedButtonStyles : buttonStyles;
};

export const CustomButtonContainer = styled.button`
  min-width: 165px;
  width: auto;
  height: 40px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 12px 0 12px;
  font-size: 14px;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  ${getButtonStyles};

  &:disabled {
    opacity: 0.6;
  }
`;
