import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 30%;
  max-width: ${(props) =>
    props.titleFor === 'camera-detail' ? '100%' : '250px'};
  height: min-content;
  opacity: ${(props) => (props.isUserLoading ? 0.6 : 1)};
  padding: 6px 10px;
  font-size: 17px;
  font-weight: bold;
  border-left: 3px solid #3fb8ac;
  border-bottom: 1px solid #3fb8ac;
  background-color: white;
  display: inline-block;
`;
