import styled from 'styled-components';

export const LoginPageContainer = styled.div`
  background-color: white;
  width: 450px;
  display: flex;
  margin: 8vw auto;
  /* border: 1px solid black; */
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.10),
    0 5px 8px rgba(0, 0, 0, 0.10);
`;
