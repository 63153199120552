import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCameraItems } from '../../redux/camera/camera.selectors';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CameraSelect = ({
  currCameraId,
  handleChangeDropdown,
}) => {
  const allCameraItems = useSelector(selectCameraItems);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cameraName, setCameraName] = useState('');

  // Update the camera name when currCameraId changes
  useEffect(() => {
    const defaultCamera = allCameraItems.find((camera) => camera.id.toString() === currCameraId);
    setCameraName(defaultCamera ? defaultCamera.name : 'Select Camera');
  }, [currCameraId, allCameraItems]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (cameraId) => {
    // Create a synthetic event to pass to handleChangeDropdown
    const syntheticEvent = {
      target: {
        value: cameraId,
      },
    };
    handleChangeDropdown(syntheticEvent);  
    handleClose();
  };

  return (
    <React.Fragment>
      <div className='d-flex gap-1 align-items-center'>
        <label style={{ marginRight: '10px'}} >カメラ選択</label>
        <Button
          variant="outlined"
          sx={{
            width: '300px',
            height: '40px',
            backgroundColor: 'white',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderColor: 'black',
            textTransform: 'none',
            fontFamily: 'inherit',
            color: 'black',
            '&:hover': {
              borderColor: 'black',
              backgroundColor: 'white',
            }
          }}
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon sx={{ transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }} />}
        >
          {cameraName}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '300px',
              fontFamily: 'inherit'
            },
          }}
        >
          {allCameraItems.map((camera) => (
            <MenuItem 
              key={camera.id} 
              onClick={() => handleSelect(camera.id.toString())}
              selected={camera.id.toString() === currCameraId}
              style={{ fontFamily: 'inherit' }}
            >
              {camera.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </React.Fragment>
  );
};

export default CameraSelect;
