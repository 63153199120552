import React, { useMemo } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';

import {
  CameraSettingFieldsContainer,
  LabelContainer,
  InputContainer,
  AllThresholdContainer,
  ThresholdLabelContainer1,
  ThresholdLabelContainer2,
  ThresholdContainer1,
  ThresholdContainer2,
  CameraInfoContainer,
} from './camera-setting-fields.styles';

import { oprStatusMap } from '../../redux/camera/camera.utils';
import TitleLabel from '../title-label/title-label.component';

const CameraSettingFields = ({ currCameraItem, handleChange }) => {
  return useMemo(() => {
    return (
      <CameraInfoContainer>
        <div style={{ padding: '15px', marginBottom: '0px'}}>
        <TitleLabel
                title={'カメラ情報'} 
                style={{gridArea: 'camera-info-title', borderBottom: 'none', boxShadow: 'none'}}
            />
        </div>
        <CameraSettingFieldsContainer>
        <LabelContainer>カメラID</LabelContainer>
        <InputContainer>
          <Tooltip
            title="カメラIDは、半角英数字と「-(ハイフン)」のみを使用してください。"
            arrow
          >
            <TextField
              sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'black',
                    },
                  }
              }}
              size='small'
              label="カメラID入力"
              name="imei"
              id="imei-required"
              onChange={handleChange}
              value={currCameraItem.imei}
              variant="outlined"
              InputLabelProps={{
                required: true,
                sx: {
                  fontFamily: 'inherit',
                  '& .MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                  
                }
              }}
            />
          </Tooltip>
        </InputContainer>
        <LabelContainer>警戒水位閾値</LabelContainer>
        <AllThresholdContainer>
          <ThresholdLabelContainer1>Lv1</ThresholdLabelContainer1>
          <ThresholdContainer1>
            <Tooltip
              title="警戒水位閾値は、Lv1 ＜ Lv2 となるように入力してください。"
              arrow
            >
              <TextField
                sx={{
                  width: '100%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'black',
                      },
                    }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
                size='small'
                label="メートル入力"
                name="level_thresh1"
                id="threshold_lv1-required"
                onChange={handleChange}
                value={
                  currCameraItem.level_thresh1
                    ? currCameraItem.level_thresh1
                    : ''
                }
                variant="outlined"
                InputLabelProps={{
                  required: true,
                  sx: {
                    fontFamily: 'inherit',
                    '& .MuiInputLabel-asterisk': {
                      color: 'red',
                    }
                  }
                }}
              />
            </Tooltip>
          </ThresholdContainer1>
          <ThresholdLabelContainer2>Lv2</ThresholdLabelContainer2>
          <ThresholdContainer2>
            <Tooltip
              title="警戒水位閾値は、Lv1 ＜ Lv2 となるように入力してください。"
              arrow
            >
              <TextField
                sx={{
                  width: '100%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'black',
                      },
                    }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
                size='small'
                label="メートル入力"
                name="level_thresh2"
                id="threshold_lv2-required"
                onChange={handleChange}
                value={
                  currCameraItem.level_thresh2
                    ? currCameraItem.level_thresh2
                    : ''
                }
                variant="outlined"
                InputLabelProps={{
                  required: true,
                  sx: {
                    fontFamily: 'inherit',
                    '& .MuiInputLabel-asterisk': {
                      color: 'red',
                    }
                  }
                }}
              />
            </Tooltip>
          </ThresholdContainer2>
        </AllThresholdContainer>
        <LabelContainer>カメラ状態</LabelContainer>
        <InputContainer>
          <TextField
            sx={{
              width: '30%',
              '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'black',
              },
            }
            }}
            size='small'
            select
            InputLabelProps={{
              required: true,
              sx: {
                fontFamily: 'inherit',
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                }
              }
            }}
            label="カメラ状態選択"
            name="camera_status"
            id="camera_status"
            onChange={handleChange}
            value={
              oprStatusMap[currCameraItem.camera_status]
                ? oprStatusMap[currCameraItem.camera_status]
                : oprStatusMap[0]
            }
            variant="outlined"
          >
            {['稼働中', '故障中', 'メンテナンス中'].map(
              (val, idx) => (
                <MenuItem key={idx} value={val} style={{ fontFamily: 'inherit' }}>
                  {val}
                </MenuItem>
              ),
            )}
          </TextField>
        </InputContainer>
        <LabelContainer>河川名</LabelContainer>
        <InputContainer>
          <TextField
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'black',
              },
            }
            }}
            size='small'
            label="河川名入力"
            name="river"
            id="river-required"
            onChange={handleChange}
            value={currCameraItem.river}
            variant="outlined"
            InputLabelProps={{
              required: true,
              sx: {
                fontFamily: 'inherit',
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                }
              }
            }}
          />
        </InputContainer>
        <LabelContainer>現在の住所</LabelContainer>
        <InputContainer>
          <TextField
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'black',
              },
            }
            }}
            size='small'
            label="住所入力"
            name="place"
            id="place-required"
            onChange={handleChange}
            value={currCameraItem.place}
            variant="outlined"
            InputLabelProps={{
              required: true,
              sx: {
                fontFamily: 'inherit',
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                }
              }
            }}
          />
        </InputContainer>
      </CameraSettingFieldsContainer>
      </CameraInfoContainer>
    );
  }, [currCameraItem, handleChange]);
};

export default CameraSettingFields;
