import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { validateChangePassword } from '../../redux/user/user.utils';

import {
  selectCurrentUser,
  selectIsUserLoading,
  selectErrorMessage,
} from '../../redux/user/user.selectors';

import {
  changePassStart,
  setErrorMessage,
} from '../../redux/user/user.actions';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ChangePasswordDialog = ({
  openPassDialog,
  setOpenPassDialog,
}) => {
  const currUser = useSelector(selectCurrentUser);
  const isUserLoading = useSelector(selectIsUserLoading);
  const errorMessage = useSelector(selectErrorMessage);
  const dispatch = useDispatch();

  const [passwordFields, setPasswordFields] = useState({
    oldPassword: '',
    newPassword1: '',
    newPassword2: '',
  });

  // clear textfields upon successful password change
  useEffect(() => {
    setPasswordFields({
      oldPassword: '',
      newPassword1: '',
      newPassword2: '',
    });
  }, [errorMessage]);

  // handle the closing of current change password dialog
  const handleClose = () => {
    dispatch(setErrorMessage(null));
    setOpenPassDialog(false);
  };

  // handle when user clicks "パスワード変更" button
  const handleSubmit = () => {
    const errorText = validateChangePassword(
      currUser.attributes.email,
      passwordFields,
    );
    if (errorText === '') {
      dispatch(changePassStart(passwordFields));
      return;
    }
    dispatch(setErrorMessage(errorText));
  };

  // update password textfield input
  const handleChange = (event) => {
    const { value, name } = event.target;
    setPasswordFields({ ...passwordFields, [name]: value });
  };

  return (
    <Dialog open={openPassDialog} onClose={handleClose}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          パスワード変更
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            color: `${
              errorMessage === 'パスワードを変更しました。'
                ? 'green'
                : 'red'
            }`,
          }}
        >
          {errorMessage}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="old-password"
          label="現在のパスワード"
          type="password"
          name="oldPassword"
          value={passwordFields.oldPassword}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'black',
              },
            }
          }}
        />
        <TextField
          margin="dense"
          id="new-password"
          label="新しいパスワード"
          type="password"
          name="newPassword1"
          value={passwordFields.newPassword1}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'black',
              },
            }
          }}
        />
        <TextField
          margin="dense"
          id="new-password2"
          label="新しいパスワード(再入力)"
          type="password"
          name="newPassword2"
          value={passwordFields.newPassword2}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'black',
              },
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          onClick={handleSubmit}
          disabled={isUserLoading}
        >
          パスワード変更
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
