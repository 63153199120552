import styled from 'styled-components';

export const CameraTableContainer = styled.div`
  grid-area: camera-table;
  .MuiBox-root {
    margin-bottom: 12px;
  }
  .MuiPaper-root {
    box-shadow: none !important;}
`;

export const TempHeader = styled.h2`
  position: relative;
  width: 90px;
`;
