import React, { useState, useEffect } from 'react';
import { getCurrentUserMunicipalitiesUserList } from '../../admin-utils.js';
import MaterialTable from '@material-table/core';
import { TABLE_ICONS } from './user-table.settings';

import { DeleteRounded } from '@mui/icons-material';
import { UserTableContainer } from './user-table.styles';
import Spinner from '../spinner/spinner.component';
import { TablePagination, Tooltip } from '@mui/material';


const UserTable = ({ handleDelete }) => {
    const [userList, setUserList] = useState([]);
    
    useEffect( () => {
        let isMounted = true;
        getCurrentUserMunicipalitiesUserList()
            .then( (municipalitiesUserList) => {
                if (isMounted) {
                    setUserList(municipalitiesUserList);
                }
            });
            return () => { isMounted = false; };
    }, [userList.length]);

    const createDataObj = (itemsArr) => {
        return itemsArr.map((item, idx) => ({
            id: item.Username,
            enabled: item.Enabled,
            accstatus: item.UserStatus,
            email:
            item.Attributes[
                item.Attributes.findIndex(
                    (element) => element.Name === 'email',
                )
            ].Value,
            updated: new Date(item.UserLastModifiedDate).toLocaleString(
                'ja-JP',
            ),
            created: new Date(item.UserCreateDate).toLocaleString('ja-JP'),
        }));
    };

    if (userList.length === 0) 
        return <Spinner />

    return (
        <UserTableContainer>
        <MaterialTable
        icons={TABLE_ICONS}
        style={{
            boxShadow: '4px 8px 7px rgba(0, 0, 0, 0.3)',
            paddingLeft: '10px',
            paddingRight: '10px',
        }}
        title="ユーザ⼀覧"
        columns={[
            {
                title: 'ユーザ名',
                field: 'id',
                cellStyle: {
                    fontSize: '11px',
                },
            },
            {
                title: '有効',
                field: 'enabled',
            },
            {
                title: '状態',
                field: 'accstatus',
                cellStyle: {
                    fontSize: '12px',
                },
            },
            {
                title: 'メール',
                field: 'email',
                cellStyle: {
                    fontSize: '16px',
                },
            },
            {
                title: '更新⽇時',
                field: 'updated',
                cellStyle: {
                    fontSize: '13px',
                },
                headerStyle: {
                    whiteSpace: 'nowrap'
                }
            },
            {
                title: '作成⽇時',
                field: 'created',
                cellStyle: {
                    fontSize: '13px',
                },
                headerStyle: {
                    whiteSpace: 'nowrap'
                }
            },
            { 
                title: '削除', 
                field: 'delete',
                render: rowData => (
                    <Tooltip title="ユーザー削除">
                        <DeleteRounded 
                            style={{ color: 'red', cursor: 'pointer' }} 
                            onClick={() => handleDelete(rowData)}
                        />
                    </Tooltip>
                ),
                headerStyle: {
                    textAlign: 'right',
            
                },
                cellStyle: {
                    textAlign: 'center',
                    fontSize: '13px',
                    verticalAlign: 'middle',
                },
            },
        ]}
        data={createDataObj(userList)}
        options={{
            headerStyle: {
                backgroundColor: '#007871',
                color: '#FFF',
            },
                padding: 'dense',
                minBodyHeight: '400px',
                maxBodyHeight: '400px',
                actionsColumnIndex: -1,
        }}
        localization={{
            header: { actions: '' },
            pagination: {
                labelRowsPerPage: '⾏数:',
                labelDisplayedRows: "{from}-{to} / {count}",
                labelRowsSelect: '⾏',
            },
            toolbar: {
                searchPlaceholder: '検索',
            },
        }}
        components={{
            Pagination: props =>  (
               <TablePagination
                    {...props}
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                    }}
                    sx={{
                        '.MuiTablePagination-selectLabel': {
                            marginTop: '5px'
                        },
                        '.MuiTypography-caption': {
                            display: 'none'
                        },
                        '.MuiTablePagination-displayedRows': {
                            marginTop: '5px'
                        }
                    }}
                />
                )
        }}
        />
        </UserTableContainer>
    );
};

export default UserTable;
