import React from 'react';
import { useSelector } from 'react-redux';

import { Pagination } from '@mui/material';

import { selectNumOfCameraPages } from '../../redux/camera/camera.selectors';

const CameraAllPagination = ({ page, setPage }) => {
    const numOfPages = useSelector(selectNumOfCameraPages);
    // fetch a new set of 8 cameras on pagination change
    const handleChange = (event, value) => {
        setPage(value);
    };
    return (
        <Pagination
            count={numOfPages}
            page={page}
            onChange={handleChange}
            sx={{gridArea: 'pagination', justifySelf: 'center'}}
            color='primary'
            variant='outlined'
        />
    );
};

export default CameraAllPagination;
